import React from "react";

export default function Inventory() {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 6.99699C0 6.74311 0.0282063 6.51744 0.0282063 6.26356C0.0564152 5.4455 0.677014 4.85311 1.49507 4.85311C3.46969 4.85311 5.44432 4.85311 7.41894 4.85311C7.6164 4.85311 7.72924 4.7967 7.87028 4.65565C8.32163 4.1761 8.80118 3.72476 9.25252 3.27342C9.42177 3.10417 9.59102 3.04775 9.8167 3.10416C10.1834 3.217 10.3245 3.66834 10.0424 3.97864C9.87311 4.1761 9.67565 4.34536 9.47818 4.54282C9.02684 4.99416 8.5755 5.4455 8.12416 5.92505C8.03953 6.00968 7.9267 6.0661 7.78565 6.0661C7.3061 6.0661 6.82655 6.0661 6.347 6.0661C6.23416 6.0661 6.12133 6.12252 6.03671 6.17894C5.52894 6.51744 5.41611 6.96879 5.41611 7.58938C5.44431 10.1846 5.44432 12.7798 5.44432 15.375C5.44432 16.0238 4.99298 16.3906 4.34417 16.2777C3.94925 16.1931 3.69536 15.8828 3.69536 15.4597C3.69536 14.6134 3.69536 13.7671 3.69536 12.9491C3.69536 12.0746 3.69536 11.1719 3.69536 10.2974C3.69536 10.241 3.69536 10.1846 3.69536 10.1282C3.66715 9.98714 3.58253 9.93072 3.44148 9.93072C3.30044 9.93072 3.21581 10.0153 3.21581 10.1282C3.21581 10.2128 3.21581 10.2692 3.21581 10.3538C3.21581 12.0464 3.21581 13.7389 3.21581 15.4597C3.21581 15.8264 3.07477 16.1085 2.76447 16.2495C2.42597 16.3906 2.11566 16.3906 1.80537 16.1649C1.55149 15.9956 1.49507 15.7417 1.49507 15.4597C1.49507 13.2876 1.49507 11.0873 1.49507 8.9152C1.49507 8.12535 1.49507 7.3355 1.49507 6.54565C1.49507 6.48923 1.52328 6.40461 1.49507 6.3764C1.43865 6.29177 1.35403 6.20714 1.2694 6.17894C1.15656 6.15073 1.04373 6.23535 1.01552 6.3764C1.01552 6.46102 1.01552 6.51744 1.01552 6.60207C1.01552 8.12535 1.01552 9.64863 1.01552 11.1437C1.01552 11.2283 1.01552 11.2847 1.01552 11.3694C0.507757 11.3412 0.169252 11.1155 0.0564169 10.6359C0.0564169 10.6077 0.0282063 10.5795 0.0282063 10.5231C-2.58302e-06 9.33833 0 8.18177 0 6.99699Z"
        fill="#8B8EA8"
      />
      <path
        d="M16.7283 3.78027C16.7283 5.7831 16.7283 7.78593 16.7283 9.78876C14.7255 9.78876 12.7509 9.78876 10.748 9.78876C10.748 7.78593 10.748 5.81131 10.748 3.78027C12.7509 3.78027 14.7255 3.78027 16.7283 3.78027Z"
        fill="#8B8EA8"
      />
      <path
        d="M13.9922 16.2762C13.9922 14.2734 13.9922 12.2987 13.9922 10.2959C15.995 10.2959 17.9979 10.2959 20.0007 10.2959C20.0007 12.2987 20.0007 14.2734 20.0007 16.2762C17.9979 16.2762 15.995 16.2762 13.9922 16.2762Z"
        fill="#8B8EA8"
      />
      <path
        d="M13.4842 16.2762C11.4814 16.2762 9.47853 16.2762 7.50391 16.2762C7.50391 14.2734 7.50391 12.2987 7.50391 10.2959C9.50674 10.2959 11.4814 10.2959 13.4842 10.2959C13.4842 12.2987 13.4842 14.2734 13.4842 16.2762Z"
        fill="#8B8EA8"
      />
      <path
        d="M3.44251 3.86462C2.37057 3.86462 1.49609 3.01835 1.49609 1.94641C1.49609 0.874476 2.37057 0 3.44251 0C4.48623 0 5.36071 0.874477 5.36071 1.91821C5.38892 2.96193 4.51444 3.86462 3.44251 3.86462Z"
        fill="#8B8EA8"
      />
    </svg>
  );
}
