import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import InputField from '../../components/InputField/InputField';
import 'animate.css';
import './style.css';
import Header from '../../components/header/Header';
import { ContactIcon } from '../../SVGS';
import { PRIMARY } from '../../constants/Colors';
import { ErrorCode, ErrorMessages } from '../../constants/ErrorCodes';
import { getDeliveryCharges, updateCharges } from '../../api/user';

export default function DeliveryCharges() {
  const icon = () => {
    return <ContactIcon width='26' height='30' fill={PRIMARY} />;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [charges, setCharges] = useState('');
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);

  // fetching initial data
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    fetchCharges();
  }, []);

  const handleChange = (e) => {
    setErr(false);
    setIsEmpty(false);

    if (e.target.value === '') {
      setErr(true);
      setErrMsg('Please enter delivery charges');
      setIsEmpty(true);
      return;
    }
    console.log(e.target.value);
    setCharges(e.target.value);
  };

  const fetchCharges = () => {
    setErr(false);
    setIsLoading(true);
    getDeliveryCharges()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCode.success) {
          setCharges(data.result.delivery_charges);
          setDeliveryCharges(data.result.delivery_charges);
        } else if (data.error_code === ErrorCode.not_exist) {
          setCharges(0);
          setDeliveryCharges(0);
          setErrorMsg('No data found');
        } else if (data.error_code === ErrorCode.failed) {
          setErr(true);
          setErrorMsg(ErrorMessages.failed);
        } else {
          setErr(true);
          setErrorMsg(data.message);
        }
      })
      .catch((err) => {
        setErr(true);
        setErrorMsg(ErrorMessages.network_error);
        setIsLoading(false);
      });
  };

  const saveOnclick = () => {
    if (charges === '' || parseInt(charges) < 0) {
      setErr(true);
      setErrMsg('Minimum must be greater than 0');
      return;
    }

    setIsLoading(true);
    updateCharges(charges)
      .then(({ data }) => {
        setIsLoading(false);
        if (
          data.error_code === ErrorCode.success ||
          data.error_code === ErrorCode.updated
        ) {
          setDeliveryCharges(charges);
        } else if (data.error_code === ErrorCode.failed) {
          setErr(true);
          setErrMsg(ErrorMessages.failed);
        } else {
          setErr(true);
          setErrMsg('Oops some error occured. EC:' + data.error_code);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErr(true);
        setErrMsg(ErrorMessages.network_error);
      });
  };

  return (
    <>
      <div className='mainDashView'>
        <div>
          <Header svg={icon} DashboardNavText={'Delivery Charges'} />
        </div>
        <div className='dashPanel' style={{ position: 'relative' }}>
          <>
            <div className='ar-dashpanel'>
              <div>
                <div className='chargesDiv'>
                  <h1>Rs. {deliveryCharges}</h1>
                </div>

                <div className='ae-inputField'>
                  <InputField
                    name={'charges'}
                    value={charges}
                    onChange={handleChange}
                    cat={'Delivery Charges'}
                    placeholder={'Delivery Charges'}
                    radius='7px'
                    width='400px'
                  />
                </div>
                <button
                  className='editPage_btn'
                  style={{
                    backgroundColor: PRIMARY,
                    color: 'white',
                    marginTop: '1rem',
                    width: '400px',
                  }}
                  disabled={isLoading || isEmpty}
                  onClick={saveOnclick}
                >
                  {isLoading ? <LoadingSpinner /> : 'Update'}
                </button>

                {err ? (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '0.95rem',
                      marginTop: '1rem',
                    }}
                  >
                    <p>{errMsg}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
